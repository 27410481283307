/*
 *
 * Company reducer
 *
 */

import { fromJS, List, Map } from 'immutable'

import { GET_INTEGRATION_SOURCE_SYSTEMS } from 'containers/IntegrationSourceSystems/constants'

import {
  RECALCULATE_COMPANY_BALANCES,
  RECALCULATE_COMPANY_BALANCES_ERROR,
  RECALCULATE_COMPANY_BALANCES_SUCCESS,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_ERROR,
  GET_SCHEDULED_INTEGRATIONS,
  GET_SCHEDULED_INTEGRATIONS_ERROR,
  GET_SCHEDULED_INTEGRATIONS_SUCCESS,
  GET_USED_DATA_GROUPS,
  GET_USED_DATA_GROUPS_ERROR,
  GET_USED_DATA_GROUPS_SUCCESS,
  INTEGRATION_FILE_UPLOAD,
  INTEGRATION_FILE_UPLOAD_SUCCESS,
  INTEGRATION_FILE_UPLOAD_ERROR,
  INTEGRATION_FILE_UPLOAD_RESET,
  GET_INTEGRATION_LOG_GROUPS_SUCCESS,
  GET_INTEGRATION_LOG_GROUPS,
  GET_INTEGRATION_LOG_FOR_GROUPS_SUCCESS,
  GET_INTEGRATION_LOG_FOR_GROUPS,
  ADD_USED_DATA_GROUP,
  DELETE_OPERATIVE_DATA_ERROR,
  DELETE_VOUCHERS_ERROR,
  DELETE_INVOICES_ERROR,
  UPDATE_COMPANY_LINKS,
  UPDATE_COMPANY_LINKS_SUCCESS,
  TRIGGER_SCHEDULED_INTEGRATION_ERROR,
  TRIGGER_SCHEDULED_INTEGRATION_SUCCESS,
} from './constants'

import ScheduledIntegrationRecord from 'records/scheduledIntegration'

const initialState = fromJS({
  error: false,
  loading: false,
  recalculatingCompanyBalances: false,
  selectedAccountingPeriod: undefined,
  fileUploadLoading: false,
  fileUploadError: false,
  scheduledIntegrations: undefined,
  scheduledIntegrationError: false,
  scheduledIntegrationsLoading: false,
  fileUploadResponse: undefined,
  dataGroups: List(),
  dataGroupsLoading: false,
  dataGroupsError: false,
  logGroup: List(),
  logGroupsLoading: false,
  logItemsByGroup: Map(),
})

function companyReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INTEGRATION_SOURCE_SYSTEMS:
      return state
        .set('recalculatingCompanyBalances', false)
        .set('selectedAccountingPeriod', false)

    case GET_SCHEDULED_INTEGRATIONS:
      return state.set('scheduledIntegrationsLoading', true).set('error', false)
    case TRIGGER_SCHEDULED_INTEGRATION_ERROR:
    case GET_SCHEDULED_INTEGRATIONS_ERROR:
      return state
        .set('scheduledIntegrationsLoading', false)
        .set('scheduledIntegrationError', action.error)
    case GET_SCHEDULED_INTEGRATIONS_SUCCESS:
      return state
        .set('scheduledIntegrationsLoading', false)
        .set('error', false)
        .set(
          'scheduledIntegrations',
          List(
            action.scheduledIntegrations.map(
              (scheduledIntegration) =>
                new ScheduledIntegrationRecord(scheduledIntegration)
            )
          )
        )
    case TRIGGER_SCHEDULED_INTEGRATION_SUCCESS:
      const scheduledIntegrations = state.get('scheduledIntegrations')
      const index = scheduledIntegrations.findIndex(
        (si) => si.id === action.scheduledIntegration.id
      )
      return state
        .set('loading', false)
        .set('error', false)
        .set(
          'scheduledIntegrations',
          scheduledIntegrations.set(
            index,
            new ScheduledIntegrationRecord(action.scheduledIntegration)
          )
        )

    case RECALCULATE_COMPANY_BALANCES:
      return state
        .set('recalculatingCompanyBalances', true)
        .set('selectedAccountingPeriod', action.id)

    case RECALCULATE_COMPANY_BALANCES_SUCCESS:
      return state
        .set('recalculatingCompanyBalances', false)
        .set('selectedAccountingPeriod', 0)
    case RECALCULATE_COMPANY_BALANCES_ERROR:
    case DELETE_OPERATIVE_DATA_ERROR:
    case DELETE_VOUCHERS_ERROR:
    case DELETE_INVOICES_ERROR:
    case UPDATE_COMPANY_ERROR:
      return state
        .set('loading', false)
        .set('error', action.error)
        .set('recalculatingCompanyBalances', false)
        .set('selectedAccountingPeriod', undefined)

    case UPDATE_COMPANY:
      return state.set('loading', true)

    case UPDATE_COMPANY_SUCCESS:
      return state
        .set('integrationExecutionLoading', false)
        .set('loading', false)

    case UPDATE_COMPANY_LINKS:
      return state.set('linksLoading', true)

    case UPDATE_COMPANY_LINKS_SUCCESS:
      return state.set('linksLoading', false)

    case INTEGRATION_FILE_UPLOAD:
      return state
        .set('fileUploadResponse', undefined)
        .set('fileUploadError', false)
        .set('fileUploadLoading', true)

    case INTEGRATION_FILE_UPLOAD_ERROR:
      return state
        .set('fileUploadLoading', false)
        .set('fileUploadError', action.error)

    case INTEGRATION_FILE_UPLOAD_RESET:
      return state
        .set('fileUploadError', false)
        .set('fileUploadLoading', false)
        .set('fileUploadResponse', undefined)

    case INTEGRATION_FILE_UPLOAD_SUCCESS:
      return state
        .set('fileUploadResponse', action.message)
        .set('fileUploadError', false)
        .set('fileUploadLoading', false)

    case GET_USED_DATA_GROUPS:
      return state.set('dataGroups', List()).set('dataGroupsLoading', true)

    case GET_USED_DATA_GROUPS_ERROR:
      return state
        .set('dataGroups', List())
        .set('dataGroupsError', action.error)

    case ADD_USED_DATA_GROUP:
      return state.get('dataGroups').includes(action.dataGroup)
        ? state
        : state.set(
            'dataGroups',
            state.get('dataGroups').push(action.dataGroup)
          )

    case GET_USED_DATA_GROUPS_SUCCESS:
      return state
        .set('dataGroups', List(action.dataGroups))
        .set('dataGroupsError', false)
        .set('dataGroupsLoading', false)

    case GET_INTEGRATION_LOG_GROUPS:
      return state.set('logGroups', List()).set('logGroupsLoading', true)

    case GET_INTEGRATION_LOG_GROUPS_SUCCESS:
      return state
        .set('logGroups', List(action.groups))
        .set('logGroupsLoading', false)

    case GET_INTEGRATION_LOG_FOR_GROUPS_SUCCESS:
      return state.setIn(['logItemsByGroup', action.groupId], action.items)

    case GET_INTEGRATION_LOG_FOR_GROUPS:
      return state.deleteIn(['logItemsByGroup', action.groupId])

    default:
      return state
  }
}

export default companyReducer

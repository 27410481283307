/*
 *
 * Company actions
 *
 */
import {
  RECALCULATE_COMPANY_BALANCES,
  RECALCULATE_COMPANY_BALANCES_SUCCESS,
  RECALCULATE_COMPANY_BALANCES_ERROR,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_ERROR,
  GET_SCHEDULED_INTEGRATIONS,
  GET_SCHEDULED_INTEGRATIONS_ERROR,
  GET_SCHEDULED_INTEGRATIONS_SUCCESS,
  GET_USED_DATA_GROUPS,
  GET_USED_DATA_GROUPS_SUCCESS,
  GET_USED_DATA_GROUPS_ERROR,
  INTEGRATION_FILE_UPLOAD,
  INTEGRATION_FILE_UPLOAD_SUCCESS,
  INTEGRATION_FILE_UPLOAD_ERROR,
  INTEGRATION_FILE_UPLOAD_RESET,
  GET_INTEGRATION_LOG_GROUPS,
  GET_INTEGRATION_LOG_FOR_GROUPS,
  GET_INTEGRATION_LOG_GROUPS_SUCCESS,
  GET_INTEGRATION_LOG_FOR_GROUPS_SUCCESS,
  ADD_USED_DATA_GROUP,
  DELETE_INVOICES,
  DELETE_INVOICES_ERROR,
  DELETE_INVOICES_SUCCESS,
  DELETE_OPERATIVE_DATA,
  DELETE_OPERATIVE_DATA_SUCCESS,
  DELETE_OPERATIVE_DATA_ERROR,
  DELETE_VOUCHERS,
  DELETE_VOUCHERS_SUCCESS,
  DELETE_VOUCHERS_ERROR,
  UPDATE_COMPANY_LINKS,
  UPDATE_COMPANY_LINKS_SUCCESS,
  UPDATE_COMPANY_LINKS_ERROR,
  TRIGGER_SCHEDULED_INTEGRATION,
  TRIGGER_SCHEDULED_INTEGRATION_ERROR,
  TRIGGER_SCHEDULED_INTEGRATION_SUCCESS,
} from './constants'

export const getScheduledIntegrations = ({ ovt }) => {
  return {
    type: GET_SCHEDULED_INTEGRATIONS,
    ovt,
  }
}

export const triggerScheduledIntegration = ({ ovt, id }) => {
  return {
    type: TRIGGER_SCHEDULED_INTEGRATION,
    ovt,
    id,
  }
}

export const getScheduledIntegrationsSuccess = (scheduledIntegrations) => {
  return {
    type: GET_SCHEDULED_INTEGRATIONS_SUCCESS,
    scheduledIntegrations,
  }
}

export const getScheduledIntegrationsError = (error) => {
  return {
    type: GET_SCHEDULED_INTEGRATIONS_ERROR,
    error,
  }
}

export const recalculateCompanyBalances = ({
  companyCode,
  end,
  id,
  start,
}) => ({
  type: RECALCULATE_COMPANY_BALANCES,
  companyCode,
  end,
  id,
  start,
})

export const recalculateCompanyBalancesError = (error) => ({
  type: RECALCULATE_COMPANY_BALANCES_ERROR,
  error,
})

export const recalculateCompanyBalancesSuccess = ({ companyCode }) => ({
  type: RECALCULATE_COMPANY_BALANCES_SUCCESS,
  companyCode,
})

export const updateCompany = ({ customerCode, company, updatedValues }) => ({
  type: UPDATE_COMPANY,
  customerCode,
  company,
  updatedValues,
})

export const updateLinks = ({ customerCode, company, links }) => ({
  type: UPDATE_COMPANY_LINKS,
  customerCode,
  company,
  links,
})

export const updateLinksSuccess = ({ company, links }) => ({
  type: UPDATE_COMPANY_LINKS_SUCCESS,
  company,
  links,
})

export const updateLinksError = (error) => ({
  type: UPDATE_COMPANY_LINKS_ERROR,
})

export const updateCompanySuccess = ({ customerCode, updatedCompany }) => ({
  type: UPDATE_COMPANY_SUCCESS,
  customerCode,
  updatedCompany,
})

export const updateCompanyError = (error) => ({
  type: UPDATE_COMPANY_ERROR,
  error,
})

export const dropInFile = ({
  uploadedFile,
  ovt,
  dataGroup,
  dataType,
  overrideSourceSystem,
  companyCode,
  budgetId,
  personnelExpenseAccountNumber,
  startMonth,
  startYear,
  endMonth,
  endYear,
  holidaySalaryCalculateConstantSymbol,
  filePassword,
}) => ({
  type: INTEGRATION_FILE_UPLOAD,
  uploadedFile,
  ovt,
  dataType,
  dataGroup,
  overrideSourceSystem,
  companyCode,
  budgetId,
  personnelExpenseAccountNumber,
  startMonth,
  startYear,
  endMonth,
  endYear,
  holidaySalaryCalculateConstantSymbol,
  filePassword,
})

export const dropInFileSuccess = (message) => ({
  type: INTEGRATION_FILE_UPLOAD_SUCCESS,
  message,
})

export const dropInFileError = (error) => ({
  type: INTEGRATION_FILE_UPLOAD_ERROR,
  error,
})

export const getDataGroups = ({ companyCode }) => ({
  type: GET_USED_DATA_GROUPS,
  companyCode,
})

export const getDataGroupsSuccess = (dataGroups) => ({
  type: GET_USED_DATA_GROUPS_SUCCESS,
  dataGroups,
})

export const addUsedDataGroup = (dataGroup) => ({
  type: ADD_USED_DATA_GROUP,
  dataGroup,
})

export const getDataGroupsError = ({ error }) => ({
  type: GET_USED_DATA_GROUPS_ERROR,
  error,
})

export const getIntegrationLogGroups = ({ companyCode }) => ({
  type: GET_INTEGRATION_LOG_GROUPS,
  companyCode,
})

export const getIntegrationLogsForGroup = ({ companyCode, groupId }) => ({
  type: GET_INTEGRATION_LOG_FOR_GROUPS,
  companyCode,
  groupId,
})

export const getIntegrationLogGroupsSuccess = (groups) => ({
  type: GET_INTEGRATION_LOG_GROUPS_SUCCESS,
  groups,
})

export const getIntegrationLogsForGroupSuccess = ({ items, groupId }) => ({
  type: GET_INTEGRATION_LOG_FOR_GROUPS_SUCCESS,
  items,
  groupId,
})

export const deleteInvoices = ({ companyCode }) => ({
  type: DELETE_INVOICES,
  companyCode,
})

export const deleteInvoicesSuccess = ({ companyCode }) => ({
  type: DELETE_INVOICES_SUCCESS,
  companyCode,
})

export const deleteInvoicesError = (error) => ({
  type: DELETE_INVOICES_ERROR,
  error,
})

export const deleteOperativeData = ({ companyCode }) => ({
  type: DELETE_OPERATIVE_DATA,
  companyCode,
})

export const deleteOperativeDataSuccess = ({ companyCode }) => ({
  type: DELETE_OPERATIVE_DATA_SUCCESS,
  companyCode,
})

export const deleteOperativeDataError = (error) => ({
  type: DELETE_OPERATIVE_DATA_ERROR,
  error,
})

export const deleteVouchers = ({ companyCode }) => ({
  type: DELETE_VOUCHERS,
  companyCode,
})

export const deleteVouchersSuccess = ({ companyCode }) => ({
  type: DELETE_VOUCHERS_SUCCESS,
  companyCode,
})

export const deleteVouchersError = (error) => ({
  type: DELETE_VOUCHERS_ERROR,
  error,
})

export const resetFileUpload = () => ({
  type: INTEGRATION_FILE_UPLOAD_RESET,
})

export const triggerScheduledIntegrationSuccess = ({
  scheduledIntegration,
}) => ({
  type: TRIGGER_SCHEDULED_INTEGRATION_SUCCESS,
  scheduledIntegration,
})

export const triggerScheduledIntegrationError = ({ error }) => ({
  type: TRIGGER_SCHEDULED_INTEGRATION_ERROR,
  error,
})

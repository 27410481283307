import axios from 'axios'

import { returnData, config, catchError } from './api-utils'

const createAccount = ({ companyCode, ...account }) =>
  axios
    .post(`${companyCode}/accounts`, account, config())
    .then(returnData)
    .catch(catchError)

const deleteAccount = ({ accountNumber, companyCode }) =>
  axios
    .delete(`${companyCode}/accounts/${accountNumber}`, config())
    .then(returnData)
    .catch(catchError)

const getAccounts = ({ companyCode }) =>
  axios
    .get(`${companyCode}/accounts`, config())
    .then(returnData)
    .catch(catchError)

const importAccounts = ({ accounts, companyCode }) =>
  axios
    .post(`${companyCode}/accounts/overwrite`, accounts, config())
    .then(returnData)
    .catch(catchError)

const getAccountListFile = ({ name }) =>
  axios
    .get(`/accounts/lists/${name}`, config())
    .then(returnData)
    .catch(catchError)

const getAccountListNames = () =>
  axios
    .get(`/accounts/lists`, config())
    .then(returnData)
    .catch(catchError)

const patchAccount = ({ accountNumber, companyCode, patch }) =>
  axios
    .patch(`${companyCode}/accounts/${accountNumber}`, patch, config())
    .then(returnData)
    .catch(catchError)

const accountApi = {
  createAccount,
  deleteAccount,
  getAccounts,
  getAccountListFile,
  getAccountListNames,
  importAccounts,
  patchAccount,
}

export default accountApi

/*
 *
 * Accounts constants
 *
 */
export const CANCEL_IMPORT_ACCOUNTS = 'app/Accounts/CANCEL_IMPORT_ACCOUNTS'
export const CANCEL_CREATE_ACCOUNT_SCHEME =
  'app/Accounts/CANCEL_CREATE_ACCOUNT_SCHEME'
export const CREATE_ACCOUNT = 'app/Accounts/CREATE_ACCOUNT'
export const CREATE_ACCOUNT_ERROR = 'app/Accounts/CREATE_ACCOUNT_ERROR'
export const CREATE_ACCOUNT_SUCCESS = 'app/Accounts/CREATE_ACCOUNT_SUCCESS'

export const DELETE_ACCOUNT = 'app/Accounts/DELETE_ACCOUNT'
export const DELETE_ACCOUNT_ERROR = 'app/Accounts/DELETE_ACCOUNT_ERROR'
export const DELETE_ACCOUNT_SUCCESS = 'app/Accounts/DELETE_ACCOUNT_SUCCESS'

export const EDIT_ACCOUNT = 'app/Accounts/EDIT_ACCOUNT'

export const GET_ACCOUNTS = 'app/Accounts/GET_ACCOUNTS'
export const GET_ACCOUNTS_ERROR = 'app/Accounts/GET_ACCOUNTS_ERROR'
export const GET_ACCOUNTS_SUCCESS = 'app/Accounts/GET_ACCOUNTS_SUCCESS'

export const IMPORT_ACCOUNTS = 'app/Accounts/IMPORT_ACCOUNTS'
export const IMPORT_ACCOUNTS_ERROR = 'app/Accounts/IMPORT_ACCOUNTS_ERROR'
export const IMPORT_ACCOUNTS_SUCCESS = 'app/Accounts/IMPORT_ACCOUNTS_SUCCESS'

export const IMPORT_ACCOUNTS_DIALOG_CREATE_ACCOUNT_SCHEME =
  'app/Accounts/IMPORT_ACCOUNTS_DIALOG_CREATE_ACCOUNT_SCHEME'
export const PARSE_ACCOUNTS = 'app/Accounts/PARSE_ACCOUNTS'
export const PARSE_ACCOUNTS_ERROR = 'app/Accounts/PARSE_ACCOUNTS_ERROR'
export const PARSE_ACCOUNTS_SUCCESS = 'app/Accounts/PARSE_ACCOUNTS_SUCCESS'
export const START_ACCOUNT_SCHEME_CREATION =
  'app/Accounts/START_ACCOUNT_SCHEME_CREATION'
export const TOGGLE_SHOW_ACCOUNT_FORM = 'app/Accounts/TOGGLE_SHOW_ACCOUNT_FORM'

export const UPDATE_ACCOUNT = 'app/Accounts/UPDATE_ACCOUNT'
export const UPDATE_ACCOUNT_ERROR = 'app/Accounts/UPDATE_ACCOUNT_ERROR'
export const UPDATE_ACCOUNT_SUCCESS = 'app/Accounts/UPDATE_ACCOUNT_SUCCESS'

/*
 *
 * Report reducer
 *
 */

import { fromJS, List } from 'immutable'
import {
  GET_VOUCHERS,
  GET_VOUCHERS_CANCEL,
  GET_VOUCHERS_ERROR,
  GET_VOUCHERS_SUCCESS,
  UI_SELECT_REPORT,
  GET_OPERATIVE_ITEMS,
  GET_OPERATIVE_ITEMS_SUCCESS,
  GET_OPERATIVE_ITEMS_ERROR,
  SET_REPORT_SETTINGS_CHANGED,
  DELETE_REPORT_BALANCE,
  DELETE_REPORT_BALANCE_SUCCESS,
  DELETE_REPORT_BALANCE_ERROR,
  GET_REPORT_BALANCES,
  GET_REPORT_BALANCES_SUCCESS,
  SET_REPORT_RECALCULATION_TRUE,
  SET_REPORT_RECALCULATION_FALSE,
  GET_REPORT_BALANCES_LIST,
  GET_REPORT_BALANCES_LIST_SUCCESS,
  GET_REPORT_BALANCES_LIST_ERROR,
  CREATE_REPORT_BALANCE,
  CREATE_REPORT_BALANCE_SUCCESS,
  SET_REPORT_BALANCES_ERROR,
  DELETE_ALL_REPORT_BALANCES,
  DELETE_ALL_REPORT_BALANCES_SUCCESS,
  DELETE_ALL_REPORT_BALANCES_ERROR,
  UPDATE_REPORT_BALANCE,
  UPDATE_REPORT_BALANCE_SUCCESS,
  UPDATE_REPORT_BALANCE_ERROR,
  SHOW_CELL_EDIT_MODAL,
} from './constants'

const initialState = fromJS({
  params: undefined,
  selectedReport: undefined,
  selectedReportId: undefined,
  showVoucher: false,
  vouchers: List(),
  vouchersLoading: false,
  vouchersError: false,
  resetReportSettingsLoading: false,
  saveReportSettingsLoading: false,
  reportSettingsChanged: false,
  reportBalances: undefined,
  reportBalancesLoading: false,
  reportBalancesError: false,
  reportRecalculation: false,
  reportBalancesList: undefined,
  reportBalancesCreateLoading: 0,
  reportBalancesDeleteLoading: false,
  showCellEditModal: false,
})

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VOUCHERS:
      return state
        .set('showVouchers', true)
        .set('vouchersLoading', true)
        .set('vouchersError', false)
        .set('vouchers', List())

    case GET_VOUCHERS_ERROR:
      return state
        .set('vouchersLoading', false)
        .set('vouchersError', action.error)

    case GET_VOUCHERS_CANCEL:
      return state
        .set('params', undefined)
        .set('showVouchers', false)
        .set('vouchersLoading', false)
        .set('vouchersError', false)
        .set('vouchers', List())
        .set('operativeItemsLoading', false)
        .set('operativeItems', List())

    case GET_VOUCHERS_SUCCESS: {
      return state
        .set('params', action.params)
        .set('vouchersLoading', false)
        .set('vouchersError', false)
        .set('vouchers', List(action.vouchers))
    }

    case UI_SELECT_REPORT:
      return state
        .set('selectedReport', action.report)
        .set('selectedReportId', action.report.id)

    case GET_OPERATIVE_ITEMS:
      return state
        .set('operativeItems', List())
        .set('operativeItemsLoading', true)

    case GET_OPERATIVE_ITEMS_SUCCESS:
      return state
        .set('operativeItems', List(action.operativeItems))
        .set('operativeItemsLoading', false)

    case GET_OPERATIVE_ITEMS_ERROR:
      return state
        .set('operativeItems', List())
        .set('operativeItemsLoading', false)

    case SET_REPORT_SETTINGS_CHANGED:
      return state.set('reportSettingsChanged', action.changed)
    case CREATE_REPORT_BALANCE_SUCCESS:
      const newReportBalancesListLoading =
        state.get('reportBalancesCreateLoading') - 1
      return state
        .set('reportBalancesCreateLoading', newReportBalancesListLoading)
        .set('reportBalancesError', false)
        .set(
          'reportBalances',
          state.get('reportBalances').concat(action.reportBalance)
        )
        .set('showCellEditModal', newReportBalancesListLoading)
        .set('reportRecalculation', true)
        .set('showCellEditModal', false)
    case GET_REPORT_BALANCES:
      return state
        .set('reportBalancesLoading', true)
        .set('reportBalancesError', false)

    case DELETE_REPORT_BALANCE:
      return state
        .set('reportBalancesDeleteLoading', true)
        .set('reportBalancesError', false)

    case DELETE_REPORT_BALANCE_ERROR:
    case DELETE_ALL_REPORT_BALANCES_ERROR:
      return state
        .set('reportBalancesDeleteLoading', false)
        .set('reportBalancesError', action.error)

    case DELETE_REPORT_BALANCE_SUCCESS:
      const filtered = state
        .get('reportBalances')
        .filter((r) => r.id !== action.realBalanceId)
      return state
        .set('reportBalances', filtered)
        .set('reportBalancesLoading', false)
        .set('reportBalancesDeleteLoading', false)
        .set('reportBalancesError', false)

    case GET_REPORT_BALANCES_SUCCESS:
      return state
        .set('reportBalancesLoading', false)
        .set('reportBalances', new List(action.reportBalances))
        .set('reportBalancesError', false)

    case SET_REPORT_RECALCULATION_TRUE:
      return state.set('reportRecalculation', true)

    case SET_REPORT_RECALCULATION_FALSE:
      return state.set('reportRecalculation', false)

    case GET_REPORT_BALANCES_LIST:
      return state
        .set('reportBalancesList', List())
        .set('reportBalancesListLoading', true)
        .set('reportBalancesError', false)

    case GET_REPORT_BALANCES_LIST_SUCCESS:
      return state
        .set('reportBalancesList', List(action.reportBalances))
        .set('reportBalancesListLoading', false)
        .set('reportBalancesError', false)

    case GET_REPORT_BALANCES_LIST_ERROR:
      return state
        .set('reportBalancesList', List())
        .set('reportBalancesListLoading', false)

    case SET_REPORT_BALANCES_ERROR:
      return state
        .set('reportBalancesLoading', false)
        .set('reportBalancesError', action.error)

    case DELETE_ALL_REPORT_BALANCES:
      return state
        .set('reportBalancesDeleteLoading', true)
        .set('reportBalancesError', false)

    case DELETE_ALL_REPORT_BALANCES_SUCCESS:
      return state
        .set('reportBalances', List())
        .set('reportBalancesDeleteLoading', false)
        .set('reportBalancesError', false)

    case CREATE_REPORT_BALANCE:
    case UPDATE_REPORT_BALANCE:
      return state
        .set(
          'reportBalancesCreateLoading',
          state.get('reportBalancesCreateLoading') + 1
        )
        .set('reportBalancesError', false)

    case UPDATE_REPORT_BALANCE_SUCCESS:
      const updatedReportBalances = state
        .get('reportBalances')
        .map((reportBalance) =>
          reportBalance.id === action.reportBalance.id
            ? action.reportBalance
            : reportBalance
        )

      return state
        .set('reportBalances', updatedReportBalances)
        .set(
          'reportBalancesCreateLoading',
          state.get('reportBalancesCreateLoading') - 1
        )
        .set('reportBalancesError', false)

    case UPDATE_REPORT_BALANCE_ERROR:
      return state
        .set(
          'reportBalancesCreateLoading',
          state.get('reportBalancesCreateLoading') - 1
        )
        .set('reportBalancesError', action.error)

    case SHOW_CELL_EDIT_MODAL:
      return state.set('showCellEditModal', action.show)

    default:
      return state
  }
}

export default reportReducer

import {
  CLEAR_REPORT_DATA_ERROR,
  GET_REPORT_DATA,
  GET_REPORT_DATA_ERROR,
  GET_REPORT_DATA_SUCCESS,
  GET_REPORT_PROGRESS_SUCCESS,
  INVALIDATE_REPORT_DATA_CACHE,
  INVALIDATE_REPORT_DATA_CACHE_ERROR,
  INVALIDATE_REPORT_DATA_CACHE_SUCCESS,
} from './constants'

export const getReportData = ({
  companyCode,
  customerCode,
  enableCaching,
  reportId,
}) => ({
  type: GET_REPORT_DATA,
  companyCode,
  customerCode,
  enableCaching,
  reportId,
})
export const getReportDataError = (error) => {
  return {
    type: GET_REPORT_DATA_ERROR,
    error,
  }
}
export const getReportDataSuccess = ({ report, reportId }) => ({
  type: GET_REPORT_DATA_SUCCESS,
  report,
  reportId,
})
export const getReportProgressSuccess = ({ progress, reportId }) => ({
  type: GET_REPORT_PROGRESS_SUCCESS,
  progress,
  reportId,
})
export const invalidateReportDataCache = ({ reportId }) => ({
  type: INVALIDATE_REPORT_DATA_CACHE,
  reportId,
})
export const invalidateReportDataCacheError = (error) => ({
  type: INVALIDATE_REPORT_DATA_CACHE_ERROR,
  error,
})
export const invalidateReportDataCacheSuccess = ({ reportId }) => ({
  type: INVALIDATE_REPORT_DATA_CACHE_SUCCESS,
  reportId,
})

export const clearReportDataError = ({ reportId }) => ({
  type: CLEAR_REPORT_DATA_ERROR,
  reportId,
})

import { fromJS } from 'immutable'

import { UPDATE_REPORT_SELECTION } from 'containers/Reports/constants'

import {
  GET_REPORT_DATA,
  GET_REPORT_DATA_ERROR,
  GET_REPORT_DATA_SUCCESS,
  INVALIDATE_REPORT_DATA_CACHE,
  INVALIDATE_REPORT_DATA_CACHE_ERROR,
  INVALIDATE_REPORT_DATA_CACHE_SUCCESS,
  GET_REPORT_PROGRESS_SUCCESS,
  CLEAR_REPORT_DATA_ERROR,
} from './constants'

const initialState = fromJS({})

const reportDatasReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORT_DATA:
      return state.set(action.reportId, {
        data: undefined,
        error: false,
        loading: true,
        progress: null,
      })

    case GET_REPORT_DATA_ERROR:
    case INVALIDATE_REPORT_DATA_CACHE_ERROR:
      return state.set(action.error.reportId, {
        loading: false,
        error: action.error,
        progress: null,
      })

    case CLEAR_REPORT_DATA_ERROR:
      return state.set(action.reportId, {
        error: undefined,
      })

    case GET_REPORT_DATA_SUCCESS:
      return state.set(action.reportId, {
        data: action.report,
        error: false,
        loading: false,
        progress: null,
      })

    case INVALIDATE_REPORT_DATA_CACHE:
      return state.set(action.reportId, {
        error: false,
        loading: true,
      })

    case INVALIDATE_REPORT_DATA_CACHE_SUCCESS:
      return state.delete(action.reportId)

    case UPDATE_REPORT_SELECTION:
      return state.delete(action.reportSelection.id.toString())

    case GET_REPORT_PROGRESS_SUCCESS:
      const { progress, reportId } = action
      return state.update(reportId, (current) => ({ ...current, progress }))

    default:
      return state
  }
}

export default reportDatasReducer

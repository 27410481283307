import React from 'react'
import { NavDropdown, Dropdown } from 'react-bootstrap'
import { injectIntl, intlShape } from 'react-intl'
import { apiConfig } from 'api/api-utils'
import useAD from 'utils/featureFlagReaders/useAD'
import NavItem from './NavItem'
import messages from './messages'

import styles from './styles.module.scss'

const gtHangfireUrl =
  apiConfig.gtUrl && apiConfig.gtUrl.replace('/api', '/hangfire')

const SystemDropdownMenu = ({
  intl: { formatMessage },
  setOpen,
  open,
  hasParent,
}) => {
  const systemDropdownItems = [
    {
      to: '/customers',
      messageId: messages.customers,
    },
    {
      to: '/systemdashboards',
      messageId: messages.dashboards,
    },
    {
      to: '/calculationconstants',
      messageId: messages.calculationConstants,
    },
    {
      to: '/accountschemetemplates',
      messageId: messages.accountSchemeTemplates,
    },
    {
      to: '/reportschemetemplates',
      messageId: messages.reportSchemeTemplates,
    },
    {
      to: '/reportschemes',
      messageId: messages.reportSchemes,
    },
    {
      to: '/systemreportselections',
      messageId: messages.systemReportSelections,
    },
    {
      to: '/integrationsourcesystems',
      messageId: messages.integrationsourcesystems,
    },
    {
      messageId: messages.gtHangfireLink,
      onClick: () => window.open(gtHangfireUrl, '_blank'),
    },
    {
      divider: true,
    },
    {
      to: '/users',
      messageId: messages.users,
    },
  ]

  if (useAD)
    systemDropdownItems.push({
      to: '/allusers',
      messageId: messages.allUsers,
    })

  return (
    <NavDropdown
      id="system-manage-nav-dropdown"
      title={
        hasParent
          ? formatMessage(messages.systemNavHeader)
          : formatMessage(messages.settings)
      }
      onToggle={(state) => setOpen(state)}
      open={open}
      className={
        hasParent
          ? styles.settingsTitleDropped
          : !open
          ? styles.settingsTitleInNav
          : `${styles.settingsTitleInNav} ${styles.active}`
      }
    >
      {systemDropdownItems.map((item) =>
        item.divider ? (
          <Dropdown.Divider key="Divider" />
        ) : (
          <NavItem
            formatMessage={formatMessage}
            {...item}
            key={item.messageId.id}
          />
        )
      )}
    </NavDropdown>
  )
}

SystemDropdownMenu.propTypes = {
  intl: intlShape.isRequired,
}

export default injectIntl(SystemDropdownMenu)

/*
 *
 * Accounts reducer
 *
 */
import { fromJS, List, Map } from 'immutable'

import { AccountRecord } from 'records'

import {
  CANCEL_CREATE_ACCOUNT_SCHEME,
  CANCEL_IMPORT_ACCOUNTS,
  CREATE_ACCOUNT_ERROR,
  CREATE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_ERROR,
  DELETE_ACCOUNT_SUCCESS,
  EDIT_ACCOUNT,
  GET_ACCOUNTS,
  GET_ACCOUNTS_ERROR,
  GET_ACCOUNTS_SUCCESS,
  IMPORT_ACCOUNTS,
  IMPORT_ACCOUNTS_DIALOG_CREATE_ACCOUNT_SCHEME,
  IMPORT_ACCOUNTS_ERROR,
  IMPORT_ACCOUNTS_SUCCESS,
  PARSE_ACCOUNTS_ERROR,
  PARSE_ACCOUNTS_SUCCESS,
  START_ACCOUNT_SCHEME_CREATION,
  TOGGLE_SHOW_ACCOUNT_FORM,
  UPDATE_ACCOUNT_ERROR,
  UPDATE_ACCOUNT_SUCCESS,
} from './constants'

const mapAccounts = (accounts) =>
  accounts.map((account) => new AccountRecord({ ...account }))

const concatAccounts = ({ accounts, updatedAccounts }) => {
  const updatedAccountNumbers = updatedAccounts.map(
    (updatedAccount) => updatedAccount.accountNumber
  )

  const filteredAccounts = accounts.filterNot((account) =>
    updatedAccountNumbers.includes(account.accountNumber)
  )

  return updatedAccounts
    .concat(filteredAccounts)
    .sortBy((account) => account.accountNumber)
}

const initialState = fromJS({
  accounts: new Map(),
  edit: undefined,
  error: false,
  parsedAccounts: undefined,
  parsedFile: undefined,
  loading: false,
  showAccountForm: false,
  showCreateAccountSchemeDialog: false,
})

function accountsReducer(state = initialState, action) {
  switch (action.type) {
    case CANCEL_IMPORT_ACCOUNTS:
      return state.set('parsedAccounts', undefined).set('parsedFile', undefined)
    case IMPORT_ACCOUNTS_DIALOG_CREATE_ACCOUNT_SCHEME:
    case CANCEL_CREATE_ACCOUNT_SCHEME:
      return state.set('showCreateAccountSchemeDialog', false)
    case CREATE_ACCOUNT_SUCCESS:
      return state
        .set('showAccountForm', false)
        .set('loading', false)
        .updateIn(['accounts', action.companyCode], (accounts) => {
          const newAccount = new AccountRecord(action.account)
          if (!accounts) {
            return new List([accounts]).sortBy(
              (account) => account.accountNumber
            )
          }
          return new List([...accounts].concat([newAccount])).sortBy(
            (account) => account.accountNumber
          )
        })

    case CREATE_ACCOUNT_ERROR:
    case DELETE_ACCOUNT_ERROR:
    case GET_ACCOUNTS_ERROR:
    case IMPORT_ACCOUNTS_ERROR:
    case PARSE_ACCOUNTS_ERROR:
    case UPDATE_ACCOUNT_ERROR:
      return state.set('loading', false).set('error', action.error)

    case DELETE_ACCOUNT_SUCCESS: {
      const newCompanyAccounts = state.getIn(['accounts', action.companyCode])
      const newAccounttMap = newCompanyAccounts.filter(
        (account) => account.accountNumber !== action.accountNumber
      )
      return state
        .set('loading', false)
        .setIn(['accounts', action.companyCode], newAccounttMap)
    }
    case EDIT_ACCOUNT:
      return state
        .set('edit', action.accountNumber)
        .set('showAccountForm', false)

    case GET_ACCOUNTS:
    case IMPORT_ACCOUNTS:
      return state.set('loading', true)
    case GET_ACCOUNTS_SUCCESS: {
      return state
        .set('loading', false)
        .setIn(
          ['accounts', action.companyCode],
          List(mapAccounts(action.accounts)).sortBy(
            (account) => account.accountNumber
          )
        )
    }
    case IMPORT_ACCOUNTS_SUCCESS:
      return state
        .set('parsedAccounts', undefined)
        .set('parsedFile', undefined)
        .setIn(
          ['accounts', action.companyCode],
          concatAccounts({
            accounts: state.getIn(['accounts', action.companyCode]),
            updatedAccounts: List(action.accounts),
          })
        )
        .set('loading', false)

    case PARSE_ACCOUNTS_SUCCESS:
      return state
        .set('loading', false)
        .set('parsedAccounts', List(action.parsedAccounts))
        .set('parsedFile', action.parsedFile)

    case START_ACCOUNT_SCHEME_CREATION:
      return state.set('showCreateAccountSchemeDialog', true)

    case TOGGLE_SHOW_ACCOUNT_FORM:
      return state.set('showAccountForm', !state.get('showAccountForm'))

    case UPDATE_ACCOUNT_SUCCESS: {
      const { account } = action
      const accounts = state.getIn(['accounts', action.companyCode])
      const index = accounts.findIndex(
        (item) => item.accountNumber === account.accountNumber
      )

      return state
        .set('loading', false)
        .set('edit', undefined)
        .setIn(
          ['accounts', action.companyCode],
          accounts.set(index, new AccountRecord(account))
        )
    }

    default:
      return state
  }
}

export default accountsReducer

/*
 *
 * Report constants
 *
 */
export const GET_VOUCHERS = 'app/Report/GET_VOUCHERS'
export const GET_VOUCHERS_CANCEL = 'app/Report/GET_VOUCHERS_CANCEL'
export const GET_VOUCHERS_ERROR = 'app/Report/GET_VOUCHERS_ERROR'
export const GET_VOUCHERS_SUCCESS = 'app/Report/GET_VOUCHERS_SUCCESS'

export const UI_SELECT_REPORT = 'app/Report/UI_SELECT_REPORT'

export const GET_OPERATIVE_ITEMS = 'app/Report/GET_OPERATIVE_ITEMS'
export const GET_OPERATIVE_ITEMS_SUCCESS =
  'app/Report/GET_OPERATIVE_ITEMS_SUCCESS'
export const GET_OPERATIVE_ITEMS_ERROR = 'app/Report/GET_OPERATIVE_ITEMS_ERROR'

export const SET_REPORT_SETTINGS_CHANGED =
  'app/Report/SET_REPORT_SETTINGS_CHANGED'
export const DELETE_REPORT_BALANCE = 'app/Report/DELETE_REPORT_BALANCE'
export const DELETE_REPORT_BALANCE_SUCCESS =
  'app/Report/DELETE_REPORT_BALANCE_SUCCESS'
export const DELETE_REPORT_BALANCE_ERROR =
  'app/Report/DELETE_REPORT_BALANCE_ERROR'

export const GET_REPORT_BALANCES = 'app/Report/GET_REPORT_BALANCES'
export const GET_REPORT_BALANCES_SUCCESS =
  'app/Report/GET_REPORT_BALANCES_SUCCESS'
export const SET_REPORT_RECALCULATION_TRUE =
  'app/Report/SET_REPORT_RECALCULATION_TRUE'
export const SET_REPORT_RECALCULATION_FALSE =
  'app/Report/SET_REPORT_RECALCULATION_FALSE'
export const GET_REPORT_BALANCES_LIST = 'app/Report/GET_REPORT_BALANCES_LIST'
export const GET_REPORT_BALANCES_LIST_SUCCESS =
  'app/Report/GET_REPORT_BALANCES_LIST_SUCCESS'
export const GET_REPORT_BALANCES_LIST_ERROR =
  'app/Report/GET_REPORT_BALANCES_LIST_ERROR'
export const CREATE_REPORT_BALANCE = 'app/Report/CREATE_REPORT_BALANCE'
export const CREATE_REPORT_BALANCE_SUCCESS =
  'app/Report/CREATE_REPORT_BALANCE_SUCCESS'

export const SET_REPORT_BALANCES_ERROR = 'app/Report/SET_REPORT_BALANCES_ERROR'

export const DELETE_ALL_REPORT_BALANCES =
  'app/Report/DELETE_ALL_REPORT_BALANCES'
export const DELETE_ALL_REPORT_BALANCES_SUCCESS =
  'app/Report/DELETE_ALL_REPORT_BALANCES_SUCCESS'
export const DELETE_ALL_REPORT_BALANCES_ERROR =
  'app/Report/DELETE_ALL_REPORT_BALANCES_ERROR'

export const UPDATE_REPORT_BALANCE = 'app/Report/UPDATE_REPORT_BALANCE'
export const UPDATE_REPORT_BALANCE_SUCCESS =
  'app/Report/UPDATE_REPORT_BALANCE_SUCCESS'
export const UPDATE_REPORT_BALANCE_ERROR =
  'app/Report/UPDATE_REPORT_BALANCE_ERROR'

export const SHOW_CELL_EDIT_MODAL = 'app/Report/SHOW_CELL_EDIT_MODAL'

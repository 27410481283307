/*
 *
 * Report actions
 *
 */

import {
  GET_VOUCHERS,
  GET_VOUCHERS_CANCEL,
  GET_VOUCHERS_ERROR,
  GET_VOUCHERS_SUCCESS,
  UI_SELECT_REPORT,
  GET_OPERATIVE_ITEMS,
  GET_OPERATIVE_ITEMS_SUCCESS,
  GET_OPERATIVE_ITEMS_ERROR,
  SET_REPORT_SETTINGS_CHANGED,
  DELETE_REPORT_BALANCE,
  DELETE_REPORT_BALANCE_SUCCESS,
  DELETE_REPORT_BALANCE_ERROR,
  GET_REPORT_BALANCES,
  GET_REPORT_BALANCES_SUCCESS,
  SET_REPORT_RECALCULATION_TRUE,
  SET_REPORT_RECALCULATION_FALSE,
  GET_REPORT_BALANCES_LIST,
  GET_REPORT_BALANCES_LIST_SUCCESS,
  GET_REPORT_BALANCES_LIST_ERROR,
  CREATE_REPORT_BALANCE,
  CREATE_REPORT_BALANCE_SUCCESS,
  SET_REPORT_BALANCES_ERROR,
  DELETE_ALL_REPORT_BALANCES,
  DELETE_ALL_REPORT_BALANCES_SUCCESS,
  DELETE_ALL_REPORT_BALANCES_ERROR,
  UPDATE_REPORT_BALANCE,
  UPDATE_REPORT_BALANCE_SUCCESS,
  UPDATE_REPORT_BALANCE_ERROR,
  SHOW_CELL_EDIT_MODAL,
} from './constants'

export const getVouchers = ({ realBalanceIds, reportSchemeId }) => ({
  type: GET_VOUCHERS,
  realBalanceIds,
  reportSchemeId,
})
export const getVouchersCancel = () => ({
  type: GET_VOUCHERS_CANCEL,
})
export const getVouchersError = (error) => ({
  type: GET_VOUCHERS_ERROR,
  error,
})
export const getVouchersSuccess = ({ params, vouchers }) => ({
  type: GET_VOUCHERS_SUCCESS,
  params,
  vouchers,
})

export const uiSelectReport = ({ report }) => ({
  type: UI_SELECT_REPORT,
  report,
})

export const getOperativeItems = ({ operativeItemIds, reportSchemeId }) => ({
  type: GET_OPERATIVE_ITEMS,
  operativeItemIds,
  reportSchemeId,
})

export const getOperativeItemsSuccess = ({ operativeItems }) => ({
  type: GET_OPERATIVE_ITEMS_SUCCESS,
  operativeItems,
})

export const getOperativeItemsError = ({ error }) => ({
  type: GET_OPERATIVE_ITEMS_ERROR,
  error,
})

export const setReportSettingsChanged = ({ changed }) => ({
  type: SET_REPORT_SETTINGS_CHANGED,
  changed,
})
export const deleteReportBalance = ({ realBalanceId, reportId }) => ({
  type: DELETE_REPORT_BALANCE,
  realBalanceId,
  reportId,
})

export const deleteReportBalanceSuccess = ({ realBalanceId }) => ({
  type: DELETE_REPORT_BALANCE_SUCCESS,
  realBalanceId,
})

export const deleteReportBalanceError = (error) => ({
  type: DELETE_REPORT_BALANCE_ERROR,
  error,
})

export const getReportBalances = ({ reportId, companyCode }) => ({
  type: GET_REPORT_BALANCES,
  reportId,
  companyCode,
})

export const setReportRecalculationTrue = () => ({
  type: SET_REPORT_RECALCULATION_TRUE,
})

export const setReportRecalculationFalse = () => ({
  type: SET_REPORT_RECALCULATION_FALSE,
})

export const getReportBalancesSuccess = ({ reportBalances }) => ({
  type: GET_REPORT_BALANCES_SUCCESS,
  reportBalances,
})

export const getReportBalancesList = ({
  reportBalanceIds,
  reportId,
  reportSchemeId,
}) => ({
  type: GET_REPORT_BALANCES_LIST,
  reportBalanceIds,
  reportId,
  reportSchemeId,
})

export const getReportBalancesListSuccess = ({ reportBalances }) => ({
  type: GET_REPORT_BALANCES_LIST_SUCCESS,
  reportBalances,
})

export const getReportBalancesListError = ({ error }) => ({
  type: GET_REPORT_BALANCES_LIST_ERROR,
  error,
})

export const createReportBalance = ({
  reportId,
  reportSchemeId,
  amount,
  description,
  accountId,
  dimensionValueIds,
  date,
  companyId,
  datasourceId,
  dataGroupId,
  quantity,
}) => ({
  type: CREATE_REPORT_BALANCE,
  reportId,
  reportSchemeId,
  amount,
  description,
  accountId,
  dimensionValueIds,
  date,
  companyId,
  datasourceId,
  dataGroupId,
  quantity,
})

export const createReportBalanceSuccess = ({ reportBalance }) => ({
  type: CREATE_REPORT_BALANCE_SUCCESS,
  reportBalance,
})

export const setReportBalancesError = (error) => ({
  type: SET_REPORT_BALANCES_ERROR,
  error,
})

export const deleteAllReportBalances = ({ reportId }) => ({
  type: DELETE_ALL_REPORT_BALANCES,
  reportId,
})

export const deleteAllReportBalancesSuccess = () => ({
  type: DELETE_ALL_REPORT_BALANCES_SUCCESS,
})

export const deleteAllReportBalancesError = ({ error }) => ({
  type: DELETE_ALL_REPORT_BALANCES_ERROR,
  error,
})

export const updateReportBalance = ({
  amount,
  description,
  id,
  accountId,
  dimensionValueIds,
  companyId,
  date,
  dataGroupId,
  quantity,
}) => ({
  type: UPDATE_REPORT_BALANCE,
  amount,
  description,
  id,
  accountId,
  dimensionValueIds,
  companyId,
  date,
  dataGroupId,
  quantity,
})

export const updateReportBalanceSuccess = ({ reportBalance }) => ({
  type: UPDATE_REPORT_BALANCE_SUCCESS,
  reportBalance,
})

export const updateReportBalanceError = ({ error }) => ({
  type: UPDATE_REPORT_BALANCE_ERROR,
  error,
})

export const setShowCellEditModal = (show) => ({
  type: SHOW_CELL_EDIT_MODAL,
  show,
})

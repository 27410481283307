/*
 *
 * Report actions
 *
 */
import {
  OPEN_REPORT_WITHOUT_SAVING,
  OPEN_REPORT_WITHOUT_SAVING_ERROR,
  OPEN_REPORT_WITHOUT_SAVING_SUCCESS,
  SAVE_REPORT,
  SAVE_REPORT_SELECTION_ERROR,
  SAVE_REPORT_SELECTION_SUCCESS,
  GET_REPORT_PROGRESS_PREVIEW_SUCCESS,
  GET_REPORT_DATA_PREVIEW,
  GET_REPORT_DATA_PREVIEW_SUCCESS,
  CLEAR_REPORT_DATA_PREVIEW,
  GET_REPORT_DATA_PREVIEW_ERROR,
  CLEAR_REPORT_DATA_PREVIEW_ERROR,
} from './constants'

export const openReportWithoutSaving = ({
  companyCode,
  customerCode,
  reportSelection,
}) => ({
  type: OPEN_REPORT_WITHOUT_SAVING,
  companyCode,
  customerCode,
  reportSelection,
})
export const openReportWithoutSavingError = (error) => ({
  type: OPEN_REPORT_WITHOUT_SAVING_ERROR,
  error,
})
export const openReportWithoutSavingSuccess = ({ report }) => ({
  type: OPEN_REPORT_WITHOUT_SAVING_SUCCESS,
  report,
})

export const getReportProgressForPreviewSuccess = ({ progress }) => ({
  type: GET_REPORT_PROGRESS_PREVIEW_SUCCESS,
  progress,
})

export const saveReport = ({
  customerCode,
  companyCode,
  oldReportSelection,
  newReportSelection,
}) => ({
  type: SAVE_REPORT,
  customerCode,
  companyCode,
  oldReportSelection,
  newReportSelection,
})

export const saveReportSelectionError = (error) => ({
  type: SAVE_REPORT_SELECTION_ERROR,
  error,
})

export const saveReportSelectionSuccess = ({
  customerCode,
  companyCode,
  reportSelection,
}) => ({
  type: SAVE_REPORT_SELECTION_SUCCESS,
  customerCode,
  companyCode,
  reportSelection,
})

export const getReportDataPreview = ({
  companyCode,
  customerCode,
  reportSelection,
}) => ({
  type: GET_REPORT_DATA_PREVIEW,
  companyCode,
  customerCode,
  reportSelection,
})

export const getReportDataPreviewSuccess = ({ report }) => ({
  type: GET_REPORT_DATA_PREVIEW_SUCCESS,
  report,
})

export const clearReportDataPreview = () => ({
  type: CLEAR_REPORT_DATA_PREVIEW,
})

export const clearReportDataPreviewError = () => ({
  type: CLEAR_REPORT_DATA_PREVIEW_ERROR,
})

export const getReportDataPreviewError = (error) => ({
  type: GET_REPORT_DATA_PREVIEW_ERROR,
  error,
})
